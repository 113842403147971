import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import LikeButton from '~/components/LikeButton';
import ShareActions from '~/components/ShareActions';
import UnauthenticatedEventActions from '~/components/UnauthenticatedEventActions';

const ConsumerEventActions = ({ id, uuid, property, isAuthenticated }) => (
    <div className="mb-4 flex justify-center">
        <div className="flex justify-center overflow-hidden rounded-full bg-[#eaeff7]">
            {isAuthenticated ? (
                <>
                    <LikeButton id={id} />
                    <ShareActions id={id} uuid={uuid} property={property} />
                </>
            ) : (
                <UnauthenticatedEventActions />
            )}
        </div>
    </div>
);

ConsumerEventActions.propTypes = {
    id: PropTypes.string,
    isAuthenticated: PropTypes.bool.isRequired,
    property: PropTypes.object,
    uuid: PropTypes.string,
};

ConsumerEventActions.defaultProps = {
    id: '',
    property: {},
    uuid: '',
};

export default connect((state) => ({ isAuthenticated: state.auth.isAuthenticated && state.auth.type === 'CONSUMER' }))(
    ConsumerEventActions
);
