import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import CopyEventButton from '~/components/CopyEventButton';
import FacebookShareButton from '~/components/FacebookShareButton';
import LinkedInShareButton from '~/components/LinkedInShareButton';
import PinterestShareButton from '~/components/PinterestShareButton';
import TwitterShareButton from '~/components/TwitterShareButton';
import './ShareEventModal.module.scss';

const ShareEventModal = (props) => {
    const { visible, handleCancel, uuid, id, property, saveShareEvent } = props;

    return (
        <Modal
            visible={visible}
            footer={null}
            onCancel={handleCancel}
            closable={false}
            className="share-event-modal bg-transparent p-0"
            width={null}
            centered
            wrapClassName="bg-transparent"
        >
            <div className="flex h-12 items-center overflow-hidden rounded-full bg-gray-200">
                <FacebookShareButton uuid={uuid} id={id} property={property} saveShareEvent={saveShareEvent} />
                <TwitterShareButton uuid={uuid} id={id} property={property} saveShareEvent={saveShareEvent} />
                <LinkedInShareButton id={id} uuid={uuid} property={property} saveShareEvent={saveShareEvent} />
                <PinterestShareButton id={id} uuid={uuid} property={property} saveShareEvent={saveShareEvent} />
                <CopyEventButton id={id} uuid={uuid} property={property} saveShareEvent={saveShareEvent} />
            </div>
        </Modal>
    );
};

ShareEventModal.propTypes = {
    handleCancel: PropTypes.func,
    id: PropTypes.string,
    property: PropTypes.object,
    saveShareEvent: PropTypes.func,
    uuid: PropTypes.string,
    visible: PropTypes.bool,
};

ShareEventModal.defaultProps = {
    handleCancel: () => {},
    id: '',
    property: {},
    saveShareEvent: () => {},
    uuid: '',
    visible: false,
};

export default ShareEventModal;
