import { useQuery, gql } from '@apollo/client';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { LoadingOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { fbButton } from 'vanilla-sharing';

const EVENT_TOTAL_SHARES = gql`
    query eventTotalShares($id: ID!) {
        event(id: $id) {
            id
            totalShares(platform: "facebook")
        }
    }
`;

const FacebookShareButton = ({ property = {}, uuid, saveShareEvent, id }) => {
    const [isLoading, setIsLoading] = useState(false);
    const { data, loading, error } = useQuery(EVENT_TOTAL_SHARES, { variables: { id } });
    const { slugUrl } = property;

    const handleFacebookShareEventClick = () => {
        setIsLoading(true);
        const url = property
            ? `https://www.realty.com.au/property/${slugUrl}`
            : `https://www.realty.com.au/event/${uuid}`;

        fbButton({ url });

        return saveShareEvent('facebook').then(() => setIsLoading(false));
    };

    if (error) return null;
    if (loading) {
        return (
            <div className="relative inline-block h-12 px-4">
                <LoadingOutlined className="center-align" />
            </div>
        );
    }

    const { event = {} } = data;
    const { totalShares } = event;

    const icon = isLoading ? <LoadingOutlined /> : <FA icon={faFacebookF} fixedWidth />;
    return (
        <Button
            className="h-12 rounded-none border-none bg-transparent px-4 leading-loose text-gray-700 hover:text-blue-500"
            onClick={handleFacebookShareEventClick}
        >
            {icon}
            &nbsp;
            {totalShares}
        </Button>
    );
};

FacebookShareButton.propTypes = {
    id: PropTypes.string.isRequired,
    property: PropTypes.object,
    saveShareEvent: PropTypes.func,
    uuid: PropTypes.string,
};

FacebookShareButton.defaultProps = {
    property: {},
    saveShareEvent: () => {},
    uuid: '',
};

export default FacebookShareButton;
