import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { ClockCircleOutlined, ShopOutlined } from '@ant-design/icons';
import { Avatar, Dropdown, Button, Menu, message } from 'antd';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import copy from 'copy-to-clipboard';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { faEllipsisV } from '@fortawesome/pro-regular-svg-icons';
import { equalsIgnoreCase } from '~/utils/string';
import PropertyCard from '../PropertyCard';

dayjs.extend(relativeTime);

class FeedPropertyEvent extends PureComponent {
    static propTypes = { event: PropTypes.object };

    static defaultProps = { event: {} };

    parsePropertyDetails = (property, text) => {
        <>
            <Link href={`/agency/${property.agency.slugUrl}`} className="font-semibold text-gray-800">
                {property.agency.name}
            </Link>{' '}
            {text}{' '}
            <Link href={`/${property.slugUrlPrefix}/${property.slugUrl}`} className="font-semibold text-gray-800">
                {property.address}
            </Link>{' '}
        </>;
    };

    handleCopyEventClick = () => {
        const { pageId, event } = this.props;

        const copyUrl = copy(`https://www.realty.com.au/${pageId}/post/${event.id}`);
        message.success('copied');
        return copyUrl;
    };

    render() {
        const {
            event: { createdAt, property, name },
            pageId,
        } = this.props;

        const copyLink = (
            <Menu>
                <Menu.Item className="p-0">
                    <div aria-hidden onClick={this.handleCopyEventClick} className="px-2 py-1">
                        <FA icon={faCopy} fixedWidth />
                        &nbsp;Copy Link
                    </div>
                </Menu.Item>
            </Menu>
        );

        return (
            <>
                <section className="flex items-center px-4">
                    <Link href={`/agency/${property.agency.slugUrl}`} className="mr-2 block py-2 text-center">
                        <Avatar
                            alt={property.agency.name}
                            size={52}
                            icon={<ShopOutlined />}
                            src={property.agency.logoUrl}
                            className="border border-white shadow"
                        />
                    </Link>
                    <div className="flex flex-col">
                        <p className="font-normal">
                            {name === 'NEW_PROPERTY' && this.parsePropertyDetails(property, 'added new property')}
                            {name === 'UPDATE_PROPERTY' &&
                                this.parsePropertyDetails(property, 'updated their property')}
                            {name === 'PROPERTY_CHANGE_STATUS' && (
                                <>
                                    {this.parsePropertyDetails(property, 'changed their property status')}
                                    to <b>{property.status}</b>{' '}
                                </>
                            )}
                        </p>
                        <time className="text-xs">
                            <ClockCircleOutlined /> {dayjs(createdAt).fromNow()}
                        </time>
                    </div>
                    {pageId && (
                        <div>
                            <div className="post-options">
                                <Dropdown overlay={copyLink} placement="bottomRight" trigger={['click']}>
                                    <Button className="border-none ">
                                        <FA icon={faEllipsisV} className="text-xl" />
                                    </Button>
                                </Dropdown>
                            </div>
                        </div>
                    )}
                </section>
                <div className="flex justify-center py-2 lg:py-0">
                    <div className="my-2 w-full">
                        <PropertyCard property={property} isFeed />
                    </div>
                </div>
            </>
        );
    }
}

export default FeedPropertyEvent;
