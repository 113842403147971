import { useQuery, gql } from '@apollo/client';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { LoadingOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { tw } from 'vanilla-sharing';

const EVENT_TOTAL_SHARES = gql`
    query eventTotalShares($id: ID!) {
        event(id: $id) {
            id
            totalShares(platform: "twitter")
        }
    }
`;

const TwitterShareButton = ({ property = {}, uuid, saveShareEvent, id }) => {
    const [isLoading, setIsLoading] = useState(false);
    const { data, loading, error } = useQuery(EVENT_TOTAL_SHARES, { variables: { id } });
    const { slugUrl } = property;
    const handleTwitterShareEventClick = () => {
        const url = property
            ? `https://www.realty.com.au/property/${slugUrl}`
            : `https://www.realty.com.au/event/${uuid}`;
        setIsLoading(true);
        tw({
            url,
            title: 'Check out this event',
            hashtags: ['realty', 'realestate', 'australia'],
        });

        return saveShareEvent('twitter').then(() => setIsLoading(false));
    };

    if (error) return null;
    if (loading) {
        return (
            <div className="h-12 px-4 relative inline-block">
                <LoadingOutlined className="center-align" />
            </div>
        );
    }

    const { event } = data;
    const { totalShares } = event || [];
    const icon = isLoading ? <LoadingOutlined /> : <FA icon={faTwitter} fixedWidth />;

    return (
        <Button
            className="border-none leading-loose h-12 px-4 rounded-none bg-transparent text-gray-700"
            onClick={handleTwitterShareEventClick}
        >
            {icon}
            &nbsp;
            {totalShares}
        </Button>
    );
};

TwitterShareButton.propTypes = {
    id: PropTypes.string.isRequired,
    property: PropTypes.object,
    saveShareEvent: PropTypes.func,
    uuid: PropTypes.string,
};

TwitterShareButton.defaultProps = {
    property: {},
    saveShareEvent: () => {},
    uuid: '',
};

export default TwitterShareButton;
