import { gql } from '@apollo/client';
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/forbid-prop-types */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from '@apollo/client/react/hoc';
import { PlusOutlined } from '@ant-design/icons';
import { message, Modal, Upload } from 'antd';
import compose from 'lodash.flowright';
import isEmpty from 'lodash/isEmpty';
import './ConsumerPostImageForm.module.scss';

class ConsumerPostImageForm extends Component {
    static propTypes = {
        keys: PropTypes.array,
        fileList: PropTypes.array,
        onChange: PropTypes.func,
        removePostImage: PropTypes.func,
        uploadPostImage: PropTypes.func,
        onUploadFocus: PropTypes.func,
        onUploadImage: PropTypes.func,
        onStoreImageKeys: PropTypes.func,
    };

    static defaultProps = {
        keys: [],
        fileList: [],
        onChange: () => {},
        removePostImage: () => {},
        uploadPostImage: () => {},
        onUploadFocus: () => {},
        onUploadImage: () => {},
        onStoreImageKeys: () => {},
    };

    constructor(props) {
        super(props);
        const { fileList, keys } = props;
        this.state = {
            previewVisible: false,
            previewImage: '',
            fileList,
            keys,
        };
    }

    handleCancel = () => this.setState({ previewVisible: false });

    handlePreview = file => {
        this.setState({
            previewImage: file.url || file.thumbUrl,
            previewVisible: true,
        });
    };

    handleChange = ({ fileList }) => {
        const { onStoreImageKeys } = this.props;
        const imageFormats = ['image/jpeg', 'image/png'];

        const filteredFileList = fileList.filter(item => imageFormats.includes(item.type));

        const reducedFileList = filteredFileList.reduce((acc, item) => {
            if (acc.find(file => file.name === item.name)) {
                message.error('Photo has already been selected');
            } else {
                acc.push(item);
            }
            return acc;
        }, []);
        this.setState({ fileList: reducedFileList }, () => {
            onStoreImageKeys(fileList);
        });
    };

    handleRemove = file => {
        const { removePostImage, onChange, onUploadImage } = this.props;
        const { keys } = this.state;

        const index = keys.findIndex(item => item === file.response);
        if (index > -1) {
            keys.splice(index, 1);
        }
        this.setState({ keys }, () => {
            onChange(keys);
            onUploadImage(isEmpty(keys));
        });

        removePostImage(file.response);
    };

    customRequest = ({ file, onSuccess, onError, onProgress }) =>
        new Promise(resolve => {
            const { uploadPostImage, onChange, onUploadImage } = this.props;
            const { fileList } = this.state;

            onUploadImage(true);

            uploadPostImage(file)
                .then(({ data: { uploadPostImage: uploadPostImageData } }) => {
                    onProgress({ percent: 100 }, file);
                    setTimeout(() => {
                        onSuccess(uploadPostImageData, file);
                    }, 500);
                    this.setState(
                        ({ keys }) => ({
                            imageKey: uploadPostImageData,
                            keys: [...keys, uploadPostImageData],
                        }),
                        () => {
                            const { keys } = this.state;
                            onChange(keys);
                            onUploadImage(false);
                        }
                    );
                    resolve(true);
                })
                .catch(() => {
                    onError();
                    onUploadImage(isEmpty(fileList));
                });
        });

    render() {
        const { previewVisible, previewImage, fileList } = this.state;
        const { onUploadFocus } = this.props;

        return (
            <div
                className="ConsumerPostImageForm clearfix bg-white rounded rounded-tl-none rounded-tr-none pt-4 pb-2 px-2 border-t border-solid border-gray-400"
                onClick={onUploadFocus}
            >
                <Upload
                    accept=".jpeg, .JPEG, .png, .PNG, .jpg, .JPG"
                    listType="picture-card"
                    fileList={fileList}
                    onPreview={this.handlePreview}
                    onChange={this.handleChange}
                    customRequest={this.customRequest}
                    onRemove={this.handleRemove}
                    multiple
                >
                    <div>
                        <PlusOutlined />
                        <div className="ant-upload-text">Upload</div>
                    </div>
                </Upload>
                <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel} closable={false}>
                    <img alt="example" style={{ width: '100%' }} src={previewImage} />
                </Modal>
            </div>
        );
    }
}

const MUTATION = gql`
    mutation uploadPostImage($file: Upload!) {
        uploadPostImage(file: $file)
    }
`;

const REMOVE_MUTATION = gql`
    mutation removePostImage($key: String!) {
        removePostImage(key: $key)
    }
`;

export default compose(
    graphql(REMOVE_MUTATION, { props: ({ mutate }) => ({ removePostImage: key => mutate({ variables: { key } }) }) }),
    graphql(MUTATION, { props: ({ mutate }) => ({ uploadPostImage: file => mutate({ variables: { file } }) }) })
)(ConsumerPostImageForm);
