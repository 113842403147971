import { useQuery, gql } from '@apollo/client';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { LoadingOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { faFacebookMessenger } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { messenger } from 'vanilla-sharing';

const EVENT_TOTAL_SHARES = gql`
    query eventTotalShares($id: ID!) {
        event(id: $id) {
            id
            totalShares(platform: "messenger")
        }
    }
`;

const MessengerShareButton = ({ property = {}, uuid, saveShareEvent, id }) => {
    const [isLoading, setIsLoading] = useState(false);
    const { data, loading, error } = useQuery(EVENT_TOTAL_SHARES, { variables: { id } });
    const { slugUrl } = property;

    const handleMessengerShareEventClick = () => {
        setIsLoading(true);

        const url = property
            ? `https://www.realty.com.au/property/${slugUrl}`
            : `https://www.realty.com.au/event/${uuid}`;

        messenger({
            url,
            fbAppId: process.env.REACT_APP_NEXT_PUBLIC_FB_APP_ID,
        });

        return saveShareEvent('messenger').then(() => setIsLoading(false));
    };

    if (error) return null;

    if (loading) {
        return (
            <div className="relative inline-block h-12 px-4">
                <LoadingOutlined className="center-align" /> 0
            </div>
        );
    }

    const { event } = data;
    const { totalShares } = event || {};

    const icon = isLoading ? <LoadingOutlined /> : <FA icon={faFacebookMessenger} fixedWidth />;
    const url = property ? `https://www.realty.com.au/property/${slugUrl}` : `https://www.realty.com.au/event/${uuid}`;

    return (
        <button
            className="h-12 rounded-none border-none bg-transparent px-4 leading-loose text-gray-700 hover:text-blue-500"
            onClick={handleMessengerShareEventClick}
        >
            <Link
                href={`//www.facebook.com/dialog/send?app_id=281338236040494&link=${url}`}
                as={`//www.facebook.com/dialog/send?app_id=281338236040494&link=${url}`}
                target="_blank"
            >
                {icon}
                {totalShares}
            </Link>
        </button>
    );
};

MessengerShareButton.propTypes = {
    id: PropTypes.string.isRequired,
    property: PropTypes.object,
    saveShareEvent: PropTypes.func,
    uuid: PropTypes.string,
};

MessengerShareButton.defaultProps = {
    property: {},
    saveShareEvent: () => {},
    uuid: '',
};

export default MessengerShareButton;
