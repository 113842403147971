import { useQuery, gql } from '@apollo/client';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { LoadingOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { faPinterestP } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { pinterest } from 'vanilla-sharing';

const EVENT_TOTAL_SHARES = gql`
    query eventTotalShares($id: ID!) {
        event(id: $id) {
            id
            totalShares(platform: "pinterest")
        }
    }
`;
const PinterestShareButton = ({ property = {}, uuid, saveShareEvent, id }) => {
    const [isLoading, setIsLoading] = useState(false);
    const { data, loading, error } = useQuery(EVENT_TOTAL_SHARES, { variables: { id } });
    const { slugUrl } = property;

    const handlePinterestShareEventClick = () => {
        const url = property
            ? `https://www.realty.com.au/property/${slugUrl}`
            : `https://www.realty.com.au/event/${uuid}`;

        setIsLoading(true);

        pinterest({
            url,
            description: 'Check out this event',
            // media: image url string,
        });
        return saveShareEvent('pinterest').then(() => setIsLoading(false));
    };

    if (error) return null;
    if (loading) {
        return (
            <div className="h-12 px-4 relative inline-block">
                <LoadingOutlined className="center-align" />
            </div>
        );
    }

    const { event = {} } = data;
    const { totalShares } = event;
    const icon = isLoading ? <LoadingOutlined /> : <FA icon={faPinterestP} fixedWidth />;

    return (
        <Button
            className="border-none leading-loose h-12 px-4 rounded-none bg-transparent text-gray-700 hover:text-red-500"
            onClick={handlePinterestShareEventClick}
        >
            {icon}
            &nbsp;
            {totalShares}
        </Button>
    );
};

PinterestShareButton.propTypes = {
    id: PropTypes.string.isRequired,
    property: PropTypes.object,
    saveShareEvent: PropTypes.func,
    uuid: PropTypes.string,
};

PinterestShareButton.defaultProps = {
    property: {},
    saveShareEvent: () => {},
    uuid: '',
};

export default PinterestShareButton;
