import { gql } from '@apollo/client';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from '@apollo/client/react/hoc';
import { LoadingOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import compose from 'lodash.flowright';
import { linkedin } from 'vanilla-sharing';

class LinkedInShareButton extends Component {
    static propTypes = {
        eventTotalShares: PropTypes.object,
        property: PropTypes.object,
        saveShareEvent: PropTypes.func,
        uuid: PropTypes.string,
    };

    static defaultProps = {
        eventTotalShares: {},
        property: {},
        saveShareEvent: () => {},
        uuid: '',
    };

    state = { isLoading: false };

    getErrorMessage = () => {
        let errorMessage = null;
        const { formErrorCode } = this.state;

        switch (formErrorCode) {
            case 'SERVER_ERROR':
                errorMessage = 'There was a problem sharing this event.';
                break;
            default:
                errorMessage = 'Unable to share event.';
                break;
        }
        return errorMessage;
    };

    handleLinkedInShareEventClick = () => {
        const { uuid, property, saveShareEvent } = this.props;
        const { slugUrl } = property || [];

        this.setState({ isLoading: true });

        const url = property
            ? `https://www.realty.com.au/proeprty/${slugUrl}`
            : `https://www.realty.com.au/event/${uuid}`;
        const type = property ? 'property' : 'event';

        linkedin({
            url,
            title: `Check out this ${type}`,
            description: `Check out this ${type} ${url}`,
        });

        return saveShareEvent('linkedin').then(() => this.setState({ isLoading: false }));
    };

    render() {
        const { isLoading } = this.state;
        const { eventTotalShares } = this.props;
        const { loading } = eventTotalShares;

        if (loading) {
            return (
                <div className="h-12 px-4 relative inline-block">
                    <LoadingOutlined className="center-align" />
                </div>
            );
        }

        const { event } = eventTotalShares;
        const { totalShares } = event || [];
        const icon = isLoading ? <LoadingOutlined /> : <FA icon={faLinkedinIn} fixedWidth />;

        return (
            <Button
                className="border-none leading-loose h-12 px-4 rounded-none bg-transparent text-gray-700 hover:text-blue-500"
                onClick={this.handleLinkedInShareEventClick}
            >
                {icon}
                &nbsp;
                {totalShares}
            </Button>
        );
    }
}

const EVENT_TOTAL_SHARES = gql`
    query eventTotalShares($id: ID!) {
        event(id: $id) {
            id
            totalShares(platform: "linkedin")
        }
    }
`;

export default compose(
    graphql(EVENT_TOTAL_SHARES, {
        name: 'eventTotalShares',
        options: ({ id }) => ({ variables: { id } }),
    })
)(LinkedInShareButton);
