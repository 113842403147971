import { useQuery, gql } from '@apollo/client';
import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { LoadingOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { faFacebookMessenger } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';

const EVENT_TOTAL_SHARES = gql`
    query eventTotalShares($id: ID!) {
        event(id: $id) {
            id
            totalShares(platform: "messenger")
        }
    }
`;

const MessengerWebShareButton = memo(({ property = {}, uuid, saveShareEvent, id }) => {
    const [isLoading, setIsLoading] = useState(false);
    const { data, loading, error } = useQuery(EVENT_TOTAL_SHARES, { variables: { id } });

    const finalSlugUrl = property?.slugUrl ?? null;

    const handleMessengerShareEventClick = () => {
        setIsLoading(true);

        const link = property
            ? `https://www.realty.com.au/property/${finalSlugUrl}`
            : `https://www.realty.com.au/event/${uuid}`;

        window.FB.ui(
            {
                method: 'send',
                link,
            },
            (response) => {
                console.log(response);
            }
        );

        saveShareEvent('messenger').then(() => setIsLoading(false));
    };

    if (error) return null;
    if (loading) {
        return (
            <div className="relative inline-block h-12 px-4">
                <LoadingOutlined className="center-align" />
            </div>
        );
    }

    const { event = {} } = data;
    const { totalShares } = event;
    const icon = isLoading ? <LoadingOutlined /> : <FA icon={faFacebookMessenger} fixedWidth />;

    return (
        <button
            className="h-12 rounded-none border-none bg-transparent px-4 leading-loose text-gray-700 hover:text-blue-500"
            onClick={handleMessengerShareEventClick}
        >
            {icon}
            &nbsp;
            {totalShares}
        </button>
    );
});

MessengerWebShareButton.propTypes = {
    id: PropTypes.string.isRequired,
    property: PropTypes.object,
    saveShareEvent: PropTypes.func,
    uuid: PropTypes.string,
};

MessengerWebShareButton.defaultProps = {
    property: {},
    saveShareEvent: () => {},
    uuid: '',
};

export default MessengerWebShareButton;
