import { useMutation, gql } from '@apollo/client';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ShareAltOutlined } from '@ant-design/icons';
import { message } from 'antd';
import { faComments } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { isMobile } from 'react-device-detect';
import CreateATopicModal from '~/components/CreateATopicModal';
import MessengerShareButton from '~/components/MessengerShareButton';
import ShareEventModal from '~/components/ShareEventModal';
import MessengerWebShareButton from '~/components/MessengerWebShareButton';

const SHARE_EVENT = gql`
    mutation shareEvent($input: CreateEventConsumerShareInput, $platform: String) {
        shareEvent(input: $input) {
            id
            totalShares(platform: $platform)
        }
    }
`;

const ShareActions = ({ id, property = {}, uuid }) => {
    const [isShowShareModal, setIsShowShareModal] = useState(false);
    const [isShowTopicModal, setIsShowTopicModal] = useState(false);
    const [shareEvent] = useMutation(SHARE_EVENT);

    const getErrorMessage = (formErrorCode) => {
        let errorMessage = null;

        switch (formErrorCode) {
            case 'SERVER_ERROR':
                errorMessage = 'There was a problem copying this event.';
                break;
            default:
                errorMessage = 'Unable to share event.';
                break;
        }
        return errorMessage;
    };

    const saveShareEvent = (platform) => {
        const { id: propertyId } = property;

        const text = platform === 'copy' ? 'Event copied to clipboard' : `Event successfully shared to ${platform}`;

        message.config({ top: '90%' });

        return shareEvent({
            variables: {
                platform,
                input: {
                    id,
                    platform,
                    propertyId,
                },
            },
        })
            .then(() => {
                message.success(text);
            })
            .catch((error) => {
                const errors = error.graphqlErrors || [];
                const formErrorCode = errors.length > 0 ? errors[0].extensions.code : null;
                message.error(getErrorMessage(formErrorCode));
            });
    };

    return (
        <>
            <ShareEventModal
                uuid={uuid}
                id={id}
                visible={isShowShareModal}
                handleCancel={() => setIsShowShareModal(false)}
                property={property}
                saveShareEvent={saveShareEvent}
            />
            <CreateATopicModal
                eventId={id}
                property={property}
                saveShareEvent={saveShareEvent}
                handleModal={() => setIsShowTopicModal(false)}
                visible={isShowTopicModal}
            />
            <div className="flex h-12 items-center">
                <button
                    className="h-12 rounded-none border-none bg-transparent px-4 leading-loose text-gray-700"
                    onClick={() => setIsShowTopicModal(true)}
                >
                    <FA icon={faComments} />
                </button>
                {isMobile ? (
                    <MessengerShareButton uuid={uuid} id={id} property={property} saveShareEvent={saveShareEvent} />
                ) : (
                    <MessengerWebShareButton uuid={uuid} id={id} property={property} saveShareEvent={saveShareEvent} />
                )}
                <button
                    onClick={() => setIsShowShareModal(true)}
                    className="h-12 rounded-none border-none bg-transparent px-4 leading-loose text-gray-700"
                >
                    <ShareAltOutlined />
                </button>
            </div>
        </>
    );
};

ShareActions.propTypes = {
    id: PropTypes.string,
    property: PropTypes.object,
    uuid: PropTypes.string,
};

ShareActions.defaultProps = {
    id: '',
    property: {},
    uuid: '',
};

export default ShareActions;
