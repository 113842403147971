import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql, withApollo } from '@apollo/client/react/hoc';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Button, Input, message, Modal, Select } from 'antd';
import { faComments, faCopy } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import copy from 'copy-to-clipboard';
import compose from 'lodash.flowright';
import { isMobile } from 'react-device-detect';
import { FacebookProvider } from 'react-facebook';
import { messenger } from 'vanilla-sharing';
import LoadingDots from '../LoadingDots';
import { ConsumerFriendsQuery, CreateTopic } from './Operations.graphql';

class CreateATopicModal extends Component {
    static propTypes = {
        createTopic: PropTypes.func,
        eventId: PropTypes.string,
        form: PropTypes.object,
        handleModal: PropTypes.func,
        property: PropTypes.object,
        visible: PropTypes.bool,
    };

    static defaultProps = {
        createTopic: () => {},
        eventId: '',
        form: {},
        handleModal: () => {},
        property: {},
        visible: false,
    };

    state = {
        data: [],
        isLoading: false,
        isSearching: false,
        uniqueId: null,
        isCopied: false,
    };

    handleSubmit = e => {
        e.preventDefault();
        const {
            form: { validateFields },
            createTopic,
            eventId,
            property,
        } = this.props;
        const { id } = property || [];
        const propertyId = id;
        this.setState({ isLoading: true });

        validateFields(async (err, values) => {
            if (!err) {
                createTopic({
                    ...values,
                    eventId,
                    propertyId,
                })
                    .then(res => {
                        if (!res) {
                            message.error('Something went wrong');
                        } else {
                            message.success('Topic has been created');
                            this.setState({ uniqueId: res ? res.data.createTopic.uniqueId : null });
                        }
                    })
                    .catch(error => {
                        console.log(error);
                        message.error('Something went wrong');
                    })
                    .finally(() => {
                        this.setState({ isLoading: false });
                        // handleModal(true);
                    });
            } else {
                this.setState({ isLoading: false });
            }
        });
    };

    handleSearch = async (value, isInitial = false) => {
        const { client } = this.props;
        if (value === '') {
            this.setState({ data: [] });
            return;
        }

        try {
            this.setState({ isSearching: true });
            const {
                data: { viewer },
            } = await client.query({
                query: ConsumerFriendsQuery,
                variables: {
                    filter: {
                        first: 7,
                        keyword: value,
                    },
                },
                fetchPolicy: isInitial ? null : 'network-only',
            });
            const {
                consumerFriendsConnection: { nodes },
            } = viewer || [];

            this.setState({
                data: nodes || [],
                isSearching: false,
            });
        } catch (error) {
            console.log(error);
        }
    };

    handleCopyUrl = () => {
        const { uniqueId } = this.state;

        copy(`https://www.realty.com.au/t/${uniqueId}`);

        this.setState({ isCopied: true });
    };

    handleMessengerShareEventClick = () => {
        const { uniqueId } = this.state;
        const url = `https://www.realty.com.au/t/${uniqueId}`;
        if (isMobile) {
            messenger({
                url,
                fbAppId: process.env.NEXT_PUBLIC_FB_APP_ID,
            });
        } else {
            window.FB.ui(
                {
                    method: 'send',
                    link: url,
                },
                response => {
                    console.log(response);
                }
            );
            console.log(window.FB);
        }
    };

    handleCancel = () => {
        const { handleModal } = this.props;
        this.setState({ uniqueId: null }, () => {
            handleModal(false);
        });
    };

    render() {
        const {
            visible,
            form: { getFieldDecorator },
            property,
        } = this.props;
        const { isLoading, isSearching, data, uniqueId, isCopied } = this.state;
        const { address } = property || [];

        return (
            <>
                <FacebookProvider appId={process.env.NEXT_PUBLIC_FB_APP_ID} version="v3.2">
                    <Modal
                        visible={visible}
                        onCancel={this.handleCancel}
                        footer={null}
                        okButtonProps={{ loading: isLoading }}
                    >
                        <Form onSubmit={this.handleSubmit}>
                            {!uniqueId && (
                                <>
                                    <h4 className="text-gray-700 text-xs text-center uppercase font-semibold py-1">
                                        Share this property
                                    </h4>
                                    <span className="text-gray-700 text-xs uppercase font-semibold py-1 inline-block">
                                        Topic
                                    </span>
                                    <Form.Item>
                                        {getFieldDecorator('name', {
                                            initialValue: address,
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Please input a topic!',
                                                },
                                            ],
                                        })(
                                            <Input
                                                prefix={
                                                    <FA
                                                        color="pink"
                                                        icon={faComments}
                                                        style={{ color: 'rgba(0,0,0,.25)' }}
                                                    />
                                                }
                                            />
                                        )}
                                    </Form.Item>
                                    <span className="text-gray-700 text-xs uppercase font-semibold py-1 inline-block">
                                        Members
                                    </span>
                                    <Form.Item>
                                        {getFieldDecorator('members', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: 'Please select your friends!',
                                                },
                                            ],
                                        })(
                                            <Select
                                                mode="multiple"
                                                // labelInValue
                                                showSearch
                                                placeholder="Select friends"
                                                notFoundContent={isSearching ? <LoadingDots /> : ''}
                                                filterOption={false}
                                                onSearch={this.handleSearch}
                                                // onChange={this.handleChange}
                                                style={{ width: '100%' }}
                                            >
                                                {data.map(
                                                    ({ id, firstName, lastName, profilePageId, email, fullName }) => (
                                                        <Select.Option
                                                            key={`${profilePageId} ${email} ${fullName}`}
                                                            value={id}
                                                        >
                                                            {firstName} {lastName}
                                                        </Select.Option>
                                                    )
                                                )}
                                            </Select>
                                        )}
                                    </Form.Item>
                                    <Form.Item className="text-right">
                                        <Button type="primary" htmlType="submit" loading={isLoading}>
                                            Create
                                        </Button>
                                    </Form.Item>
                                </>
                            )}
                            {uniqueId && (
                                <>
                                    <span className="text-gray-700 font-semibold py-1 inline-block mr-4">
                                        Share with others through URL:
                                    </span>
                                    <div className="p-3 border rounded bg-gray-400 flex justify-around items-center">
                                        <div>
                                            https://www.realty.com.au/t/
                                            {uniqueId}
                                        </div>
                                        <div>
                                            <Button onClick={this.handleCopyUrl}>
                                                <FA icon={faCopy} className="mr-1" /> {isCopied ? ' Copied' : ' Copy'}
                                            </Button>
                                        </div>
                                    </div>
                                    <div className="flex justify-center my-2">or</div>
                                    <div className="flex justify-center">
                                        <Button type="primary" onClick={this.handleMessengerShareEventClick}>
                                            Share to messenger
                                        </Button>
                                    </div>
                                </>
                            )}
                        </Form>
                    </Modal>
                </FacebookProvider>
            </>
        );
    }
}

const CreateATopicModalWithMutation = compose(
    withApollo,
    graphql(CreateTopic, {
        props: ({ mutate }) => ({
            createTopic: ({ name, members, eventId, propertyId }) =>
                mutate({
                    variables: {
                        name,
                        members,
                        eventId,
                        propertyId,
                    },
                }),
        }),
    })
)(CreateATopicModal);
export default Form.create()(CreateATopicModalWithMutation);
