import { gql } from '@apollo/client';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from '@apollo/client/react/hoc';
import { LoadingOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import copy from 'copy-to-clipboard';
import compose from 'lodash.flowright';

class CopyEventButton extends Component {
    static propTypes = {
        eventTotalShares: PropTypes.object,
        property: PropTypes.object,
        saveShareEvent: PropTypes.func,
        uuid: PropTypes.string,
    };

    static defaultProps = {
        eventTotalShares: {},
        property: {},
        saveShareEvent: () => {},
        uuid: '',
    };

    state = { isLoading: false };

    handleCopyEventClick = () => {
        const { uuid, property, saveShareEvent } = this.props;
        const { slugUrl } = property || [];
        this.setState({ isLoading: true });

        if (property) {
            copy(`https://www.realty.com.au/property/${slugUrl}`);
        } else {
            copy(`https://www.realty.com.au/event/${uuid}`);
        }

        return saveShareEvent('copy').then(() => this.setState({ isLoading: false }));
    };

    render() {
        const { isLoading } = this.state;
        const { eventTotalShares } = this.props;
        const { loading } = eventTotalShares;

        if (loading) {
            return (
                <div className="h-12 px-4 relative inline-block">
                    <LoadingOutlined className="center-align" />
                </div>
            );
        }

        // const { event } = eventTotalShares;
        // const { totalShares } = event || [];
        const icon = isLoading ? <LoadingOutlined /> : <FA icon={faCopy} fixedWidth />;

        return (
            <Button
                className="border-none leading-loose h-12 px-4 rounded-none bg-transparent text-gray-700"
                onClick={this.handleCopyEventClick}
            >
                {icon}
                &nbsp;
                {/* {totalShares} */}
            </Button>
        );
    }
}

const EVENT_TOTAL_SHARES = gql`
    query eventTotalShares($id: ID!) {
        event(id: $id) {
            id
            totalShares(platform: "copy")
        }
    }
`;

export default compose(
    graphql(EVENT_TOTAL_SHARES, {
        name: 'eventTotalShares',
        options: ({ id }) => ({ variables: { id } }),
    })
)(CopyEventButton);
