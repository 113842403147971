/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/forbid-prop-types */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { ClockCircleOutlined, EnvironmentOutlined } from '@ant-design/icons';
import { Avatar, Button, Dropdown, Menu, message } from 'antd';
import { faEllipsisV } from '@fortawesome/pro-regular-svg-icons';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import isEmpty from 'lodash/isEmpty';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import Linkify from 'react-linkify';
import copy from 'copy-to-clipboard';
import clsx from 'clsx';
import ConsumerPostMeta from '../ConsumerPostMeta';
import DeletePostEvent from '../DeletePostEvent';
import ImagesSliderModal from '../ImagesSliderModal';
import UpdatePostEvent from '../UpdatePostEvent';
import PRIMARY from '~/constants/colors';
import styles from './FeedPostEvent.module.scss';

dayjs.extend(relativeTime);

class FeedPostEvent extends Component {
    static propTypes = {
        event: PropTypes.object,
        pageId: PropTypes.string,
        consumerPageId: PropTypes.string,
        onDelete: PropTypes.func,
        onEdit: PropTypes.func,
        recalculateHeight: PropTypes.func,
    };

    static defaultProps = {
        event: {},
        pageId: null,
        consumerPageId: null,
        onDelete: () => {},
        onEdit: () => {},
        recalculateHeight: () => {},
    };

    state = {
        carouselVisible: false,
        carouselImages: null,
        initialSlide: null,
        contentHeight: 0,
    };

    componentDidMount = () => {
        const { recalculateHeight } = this.props;
        const { contentHeight } = this.state;
        if (contentHeight === 0 && this.content) {
            this.setState({ contentHeight: this.content.clientHeight });
            setTimeout(() => {
                recalculateHeight();
            }, 200);
        }
    };

    handleReadMoreContent = (readMoreContent) => {
        const { recalculateHeight } = this.props;

        if (readMoreContent) {
            this.setState({ readMoreContent });
            recalculateHeight();
        }
    };

    handleImageCarousel = (images, index) => {
        this.setState({
            carouselVisible: true,
            carouselImages: images,
            initialSlide: index,
        });
    };

    onImagesModalClose = () => {
        this.setState({ carouselVisible: false });
    };

    handleCopyEventClick = () => {
        const { pageId, event } = this.props;

        const copyUrl = copy(`https://www.realty.com.au/${pageId}/post/${event.id}`);
        message.success('copied');
        return copyUrl;
    };

    render() {
        const { onEdit, onDelete, consumerPageId, pageId, event } = this.props;
        const { carouselVisible, carouselImages, initialSlide, readMoreContent } = this.state;
        const { createdAt, post } = event;
        const {
            contributor: { firstName, lastName, initials, profilePicture, name, profileImage, profilePageId },
            isEdited,
            isOwner,
            images,
            content,
            checkIn,
        } = post;

        const metas = {
            description: post.metaDescription,
            host: post.metaHost,
            image: post.metaImage,
            title: post.metaTitle,
            url: post.metaUrl,
        };

        const menu = (
            <Menu>
                <Menu.Item className="p-0">
                    <UpdatePostEvent event={event} onEdit={onEdit} pageId={pageId} consumerPageId={consumerPageId} />
                </Menu.Item>
                <Menu.Item className="p-0">
                    <DeletePostEvent
                        event={event}
                        onDelete={onDelete}
                        pageId={pageId}
                        consumerPageId={consumerPageId}
                    />
                </Menu.Item>
                {pageId && (
                    <Menu.Item className="p-0">
                        <div aria-hidden onClick={this.handleCopyEventClick} className="px-2 py-1">
                            <FA icon={faCopy} fixedWidth />
                            &nbsp;Copy Link
                        </div>
                    </Menu.Item>
                )}
            </Menu>
        );

        const copyLink = (
            <Menu>
                <Menu.Item className="p-0">
                    <div aria-hidden onClick={this.handleCopyEventClick} className="px-2 py-1">
                        <FA icon={faCopy} fixedWidth />
                        &nbsp;Copy Link
                    </div>
                </Menu.Item>
            </Menu>
        );

        const displayContent = content && !readMoreContent ? content.slice(0, 500) : content;

        return (
            <>
                <section
                    className="FeedPostEvent flex items-center justify-center px-4"
                    ref={(ref) => {
                        this.postFeed = ref;
                    }}
                >
                    <div className="mr-2 block py-2 text-center">
                        <Link
                            href={`/${pageId || profilePageId || name || `${firstName}${lastName}`}`}
                            className="font-semibold text-gray-800"
                        >
                            <Avatar
                                alt={`${firstName} ${lastName}`}
                                size={52}
                                src={profilePicture || profileImage}
                                style={{
                                    color: PRIMARY,
                                    backgroundColor: '#fff',
                                    border: `2px solid ${PRIMARY}`,
                                    width: 52,
                                    height: 52,
                                    fontWeight: 500,
                                }}
                            >
                                {initials}
                            </Avatar>
                        </Link>
                    </div>
                    <div className="flex grow flex-col">
                        <p className="text-sm font-normal">
                            <Link
                                href={`/${pageId || profilePageId || name || `${firstName}${lastName}`}`}
                                className="font-semibold text-gray-800"
                            >
                                {name}
                                {firstName}
                                {lastName}
                            </Link>
                            &nbsp;
                            {checkIn ? (
                                <>
                                    is at &nbsp;
                                    <span className="font-semibold">
                                        <EnvironmentOutlined className="text-base" />
                                        &nbsp;
                                        {checkIn}
                                    </span>
                                </>
                            ) : (
                                'added a new post'
                            )}
                        </p>
                        <div>
                            <time className="text-sm">
                                {dayjs().diff(dayjs(createdAt), 'days') <= 30 && (
                                    <>
                                        <ClockCircleOutlined /> {dayjs(createdAt).fromNow()}
                                    </>
                                )}
                            </time>
                            {isEdited && (
                                <span className="text-sm text-gray-700">
                                    &nbsp;&bull; Edited&nbsp;
                                    {/* {dayjs(updatedAt).fromNow()} */}
                                </span>
                            )}
                        </div>
                    </div>
                    {isOwner && (
                        <div className="post-options">
                            <Dropdown overlay={menu} placement="bottomRight" trigger={['click']}>
                                <button className="border-none px-4 py-1.5 text-black text-opacity-80">
                                    <FA icon={faEllipsisV} style={{ fontSize: '1.25rem' }} />
                                </button>
                            </Dropdown>
                        </div>
                    )}
                    {!isOwner && pageId && (
                        <div>
                            <div className="post-options">
                                <Dropdown overlay={copyLink} placement="bottomRight" trigger={['click']}>
                                    <button className="border-none px-4 py-1.5 text-black text-opacity-80">
                                        {/* padding: 5.6px 15px; */}
                                        <FA icon={faEllipsisV} style={{ fontSize: '1.25rem' }} />
                                    </button>
                                </Dropdown>
                            </div>
                        </div>
                    )}
                </section>
                <div
                    className={`${styles['feed-post-content']} ${
                        styles['content-wrapper']
                    } whitespace-pre-wrap p-4 text-sm ${readMoreContent ? styles['content-readmore'] : ''}`}
                >
                    <div className="overflow-hidden break-words transition">
                        <Linkify properties={{ target: '_blank' }}>{displayContent}</Linkify>
                    </div>

                    {content && content.length > 500 && !readMoreContent && (
                        <p
                            className={`${styles['content-toggle']} cursor-pointer font-medium text-blue-600`}
                            onClick={() => this.handleReadMoreContent(!readMoreContent)}
                            aria-hidden
                        >
                            <span className="rounded-lg border border-blue-500 bg-white px-2 py-1">
                                {readMoreContent ? 'Read less' : 'Read more'}
                            </span>
                        </p>
                    )}
                </div>
                {!isEmpty(images) && (
                    <>
                        {images.length === 1 &&
                            images.map(({ url, height }) => (
                                <div
                                    key={url}
                                    onClick={() => this.handleImageCarousel(images, 0)}
                                    className={`${styles.FeedSingleImage} cursor-pointer pt-4 text-center`}
                                    style={{
                                        height: height * (height > 500 ? 0.5 : 1),
                                        width: '100%',
                                    }}
                                >
                                    <img
                                        alt=""
                                        src={url}
                                        className="w-full"
                                        style={{
                                            height: '100%',
                                            width: '100%',
                                            objectFit: 'cover',
                                            position: 'relative',
                                            top: '50%',
                                            transform: 'translateY(-50%)',
                                        }}
                                    />
                                </div>
                            ))}
                        {images.length > 1 && images.length <= 3 && (
                            <div className={`${styles.FeedMultipleImages} flex flex-wrap pt-4`}>
                                {images.map(({ url }, index) => {
                                    const imageBoxWidth =
                                        index === 0 ? `${styles.FeedFullImageBox} mb-1` : styles.FeedHalfImageBox;

                                    return (
                                        <div
                                            key={url}
                                            style={{ backgroundImage: `url(${url})` }}
                                            onClick={() => this.handleImageCarousel(images, index)}
                                            className={clsx(
                                                imageBoxWidth,
                                                `${index === 1 ? 'mr-1' : ''} ${
                                                    styles.FeedImageBox
                                                } shrink-0 grow cursor-pointer`
                                            )}
                                        />
                                    );
                                })}
                            </div>
                        )}
                        {images.length > 3 && (
                            <div className={`${styles.FeedMultipleImages} flex flex-wrap pt-4`}>
                                {images.slice(0, 3).map(({ url }, index) => {
                                    const extraImagesCount = `+ ${images.length - 3}`;
                                    const imageBoxWidth =
                                        index === 0 ? `${styles.FeedFullImageBox} mb-1` : styles.FeedHalfImageBox;
                                    const imageLayout =
                                        index === 2 ? (
                                            <div
                                                key={url}
                                                style={{ backgroundImage: `url(${url})` }}
                                                className={`${styles.FeedImageBox} ${imageBoxWidth} relative shrink-0 grow`}
                                            >
                                                <div
                                                    className="absolute z-20 flex h-full w-full cursor-pointer items-center justify-center"
                                                    onClick={() => this.handleImageCarousel(images, index)}
                                                    style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}
                                                >
                                                    <div className="text-5xl font-extralight text-white">
                                                        {extraImagesCount}
                                                    </div>
                                                </div>
                                            </div>
                                        ) : (
                                            <div
                                                key={url}
                                                style={{ backgroundImage: `url(${url})` }}
                                                onClick={() => this.handleImageCarousel(images, index)}
                                                className={`${styles.FeedImageBox} ${imageBoxWidth} ${
                                                    index === 1 ? 'mr-1' : ''
                                                } shrink-0 grow cursor-pointer`}
                                            />
                                        );

                                    return imageLayout;
                                })}
                            </div>
                        )}
                    </>
                )}
                {post.metaDescription && (
                    <div className="lg:py-0 ">
                        <section>
                            <ConsumerPostMeta
                                {...metas}
                                onRemoveMeta={this.onRemoveMeta}
                                onRemoveImage={this.onRemoveImage}
                            />
                        </section>
                    </div>
                )}
                <ImagesSliderModal
                    visible={carouselVisible}
                    images={carouselImages}
                    initialSlide={initialSlide}
                    onClose={this.onImagesModalClose}
                />
            </>
        );
    }
}

export default FeedPostEvent;
