import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';
import { HeartOutlined, ShareAltOutlined } from '@ant-design/icons';
import { Button, message, Modal } from 'antd';
import { faFacebookMessenger } from '@fortawesome/free-brands-svg-icons';
import { faComment } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import GuestLoginForm from '~/components/GuestLoginForm';
import { loginUser } from '~/actions';

const UnauthenticatedEventActions = memo(({ onLoginUser }) => {
    const [isShowLoginModal, setIsShowLoginModal] = useState(false);
    const handleClick = () => {
        setIsShowLoginModal(true);
        message.error('You need login to do this action');
    };

    const handleLoginSuccess = (loginData) => {
        const { accessToken, expiresIn, refreshToken, user, type } = loginData;

        onLoginUser({
            accessToken,
            expiresIn,
            refreshToken,
            user,
            type,
        });
        setIsShowLoginModal(false);
    };

    const onLoginSuccessAccount = (loginData) =>
        new Promise((resolve) => {
            handleLoginSuccess(loginData);
            resolve(true);
        });

    return (
        <>
            <Modal
                wrapClassName="vertical-center-modal"
                visible={isShowLoginModal}
                onOk={() => setIsShowLoginModal(false)}
                onCancel={() => setIsShowLoginModal(false)}
                footer={null}
            >
                <GuestLoginForm onSuccess={handleLoginSuccess} onSuccessAccount={onLoginSuccessAccount} />
            </Modal>
            <button
                className="flex h-12 items-center border-0 bg-transparent px-4 leading-loose text-slate-600 transition hover:rounded-r-none hover:bg-white hover:text-primary-500"
                onClick={handleClick}
            >
                <HeartOutlined />
            </button>
            <button
                className="flex h-12 items-center border-0 bg-transparent px-4 leading-loose text-slate-600 transition hover:bg-white hover:text-primary-500"
                onClick={handleClick}
            >
                <FA icon={faComment} />
            </button>
            <button
                className="flex h-12 items-center border-0 bg-transparent px-4 leading-loose text-slate-600 transition hover:bg-white hover:text-primary-500"
                onClick={handleClick}
            >
                <FA icon={faFacebookMessenger} />
            </button>
            <button
                className="flex h-12 items-center border-0 bg-transparent px-4 leading-loose text-slate-600 transition hover:bg-white hover:text-primary-500"
                onClick={handleClick}
            >
                <ShareAltOutlined />
            </button>
        </>
    );
});

UnauthenticatedEventActions.propTypes = { onLoginUser: PropTypes.func };

UnauthenticatedEventActions.defaultProps = { onLoginUser: () => {} };

export default connect(
    (state) => ({ isAuthenticated: state.auth.isAuthenticated }),
    (dispatch) => ({
        onLoginUser(accessToken) {
            dispatch(loginUser(accessToken));
        },
    })
)(UnauthenticatedEventActions);
