import React from 'react';
import { Collapse } from 'antd';
import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';

type Props = {
    // eslint-disable-next-line react/require-default-props
    error?: any;
    // eslint-disable-next-line react/require-default-props
    className?: string;
    // eslint-disable-next-line react/require-default-props
    title?: string;
    // eslint-disable-next-line react/require-default-props
    description?: string;
    // eslint-disable-next-line react/require-default-props
    showButton?: boolean;
    // eslint-disable-next-line react/require-default-props
    buttonText?: string;
    // eslint-disable-next-line react/require-default-props
    onClickButton?: () => {};
};

const Error = (props: Props) => {
    const {
        title = 'Oops!',
        description = 'Something went wrong',
        showButton = false,
        buttonText = 'Dismiss',
        onClickButton = () => {},
        error = null,
        className = '',
    } = props;

    return (
        <div className="custom-error flex items-center justify-center ">
            <div className={`flex flex-col items-center justify-center rounded-lg ${className}`}>
                <div className="flex flex-col items-center justify-center space-y-3 p-8">
                    <FA icon={faExclamationTriangle} className="text-red-400" size="5x" />
                    <div className="text-2xl">{title}</div>
                    <div className="font-hairline text-xl">{description}</div>
                    {process.env.NODE_ENV === 'development' && error && (
                        <Collapse>
                            <Collapse.Panel header={JSON.stringify(error).slice(0, 50)} key="1">
                                <div className="text-base font-extralight text-gray-600">{error}</div>
                            </Collapse.Panel>
                        </Collapse>
                    )}
                </div>
                {showButton && (
                    <div
                        className="h-12 w-full cursor-pointer bg-primary-400 text-center text-2xl leading-loose text-white"
                        onClick={onClickButton}
                    >
                        {buttonText}
                    </div>
                )}
            </div>
        </div>
    );
};

export default Error;
