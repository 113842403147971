import { gql } from '@apollo/client';
/* eslint-disable react/forbid-prop-types */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql, withApollo } from '@apollo/client/react/hoc';
import { EditOutlined, EnvironmentFilled, LoadingOutlined } from '@ant-design/icons';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, message, Modal, Select, Spin } from 'antd';
import getUrls from 'get-urls';
import compose from 'lodash.flowright';
import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import LocationsConnectionQuery from '~/queries/LocationsConnectionQuery.graphql';
import PRIMARY from '~/constants/colors';
import ConsumerPostImageForm from '~/components/ConsumerPostImageForm';
import ConsumerPostMeta from '~/components/ConsumerPostMeta';
import { SiteMetaQuery } from './Operations.graphql';
import './UpdatePostEvent.module.scss';

const { Option } = Select;

class UpdatePostEvent extends Component {
    static propTypes = {
        event: PropTypes.object,
        updatePostEvent: PropTypes.func,
        form: PropTypes.object,
        onEdit: PropTypes.func,
        client: PropTypes.object,
    };

    static defaultProps = {
        event: {},
        updatePostEvent: () => {},
        form: {},
        onEdit: () => {},
        client: {},
    };

    constructor(props) {
        super(props);
        const {
            event: {
                post: { metaHost, metaUrl, metaDescription, metaImage, metaTitle, images },
            },
        } = props;

        const fileList = images
            ? images.map(({ url }, index) => {
                  const fileName = url.substr(url.lastIndexOf('/') + 1);
                  const fileExt = fileName.substr(fileName.indexOf('.'));
                  const type = fileExt === 'jpg' || fileExt === 'jpeg' ? 'image/jpeg' : 'image/png';

                  return {
                      uid: index,
                      name: fileName,
                      response: fileName,
                      status: 'done',
                      url,
                      type,
                  };
              })
            : null;

        const imageKeys = images ? images.map(({ url }) => url.substr(url.lastIndexOf('/') + 1)) : null;

        this.state = {
            icon: 'edit',
            visible: false,
            isPasting: false,
            fileList,
            imageKeys,
            isSearching: false,
            dataSource: [],
            metas: {
                host: metaHost,
                url: metaUrl,
                description: metaDescription,
                image: metaImage,
                title: metaTitle,
            },
        };

        this.handleSearch = debounce(this.handleSearch, 500);
    }

    onPaste = () => {
        this.setState({ isPasting: true });
    };

    onRemoveMeta = () => {
        this.setState({ metas: null });
    };

    onRemoveImage = () => {
        this.setState(({ metas }) => ({ metas: Object.assign({}, metas, { image: null }) }));
    };

    onResetMetas = () => {
        const {
            event: {
                post: { metaHost, metaUrl, metaDescription, metaImage, metaTitle, images },
            },
            form: { resetFields },
        } = this.props;

        const fileList = images
            ? images.map(({ url }, index) => {
                  const fileName = url.substr(url.lastIndexOf('/') + 1);
                  const fileExt = fileName.substr(fileName.indexOf('.'));
                  const type = fileExt === 'jpg' || fileExt === 'jpeg' ? 'image/jpeg' : 'image/png';

                  return {
                      uid: index,
                      name: fileName,
                      response: fileName,
                      status: 'done',
                      url,
                      type,
                  };
              })
            : null;

        const imageKeys = images ? images.map(({ url }) => url.substr(url.lastIndexOf('/') + 1)) : null;

        this.setState({
            metas: {
                host: metaHost,
                url: metaUrl,
                description: metaDescription,
                image: metaImage,
                title: metaTitle,
            },
            fileList,
            imageKeys,
        });
        resetFields();
    };

    onChange = (e) => {
        const { isPasting } = this.state;

        if (isPasting) {
            const urls = getUrls(e.target.value);

            if (urls.size > 0) {
                const setIter = urls.values();

                const url = setIter.next().value;
                this.queryMetaTags(url);

                this.setState({ isPasting: false });
            }
        }
    };

    handleSearch = async (value) => {
        const { client } = this.props;
        const keyword = value;

        this.setState({ isSearching: true });

        try {
            const {
                data: { locationsConnection },
            } = await client.query({
                query: LocationsConnectionQuery,
                variables: {
                    locationsFilter: {
                        first: 10,
                        keyword,
                        type: 'SUBURB',
                    },
                },
            });
            this.setState({
                isSearching: false,
                dataSource: locationsConnection.nodes || [],
            });
        } catch (error) {
            console.log(error);
        }
    };

    handleSubmit = () => {
        message.config({ top: 24 });
        const {
            event: {
                post: { id },
            },
            updatePostEvent,
            form: { validateFields },
            onEdit,
        } = this.props;
        const { metas, imageKeys } = this.state;

        this.setState({
            icon: 'loading',
            isLoading: true,
        });

        validateFields((err, values) => {
            if (!err) {
                const postInputValues = values;

                Object.assign(postInputValues, {
                    postId: id,
                    images: imageKeys,
                });

                if (metas) {
                    Object.assign(postInputValues, {
                        metaHost: metas.host || null,
                        metaUrl: metas.url || null,
                        metaDescription: metas.description || null,
                        metaImage: metas.image || null,
                        metaTitle: metas.title || null,
                    });
                } else {
                    Object.assign(postInputValues, {
                        metaHost: null,
                        metaUrl: null,
                        metaDescription: null,
                        metaImage: null,
                        metaTitle: null,
                    });
                }

                updatePostEvent(postInputValues)
                    .then(({ data: { updatePostEvent: updatePostEventResult } }) => {
                        if (updatePostEventResult) {
                            message.destroy();
                            message.success('Your post has been edited');
                            onEdit();
                        } else {
                            message.destroy();
                            message.error('Something went wrong editing your post');
                        }
                    })
                    .catch((error) => console.log(error))
                    .finally(() => {
                        this.setState({
                            icon: 'edit',
                            isLoading: false,
                        });
                        this.showEditPostModal(false);
                    });
            } else {
                message.destroy();
                message.error('Something went wrong');
                this.setState({
                    icon: 'edit',
                    isLoading: false,
                });
            }
        });
    };

    queryMetaTags = async (url) => {
        const { client } = this.props;

        try {
            const {
                data: { siteMeta },
            } = await client.query({
                query: SiteMetaQuery,
                variables: { url },
            });

            this.setState({ metas: siteMeta || null });
        } catch (error) {
            console.log(error);
        }
    };

    showEditPostModal = (visible) => {
        this.setState({ visible });
    };

    onUploadImage = () => {
        const {
            form: { getFieldValue },
        } = this.props;
        this.setState({ imageKeys: getFieldValue('images') });
    };

    onStoreImageKeys = (fileList) => {
        this.setState({ fileList });
    };

    renderOptions = (dataSource = []) => {
        const filteredResult = dataSource.filter((suburb) => suburb !== null);

        return filteredResult.map((suburb) => (
            <Option key={suburb.id} className="py-3" value={suburb.full}>
                <span className="text-base">{suburb.full}</span>
            </Option>
        ));
    };

    render() {
        const {
            event,
            form: { getFieldDecorator },
        } = this.props;
        const { icon, visible, metas, isLoading, fileList, imageKeys, isSearching, dataSource } = this.state;
        const {
            post: { content, images, checkIn },
        } = event;

        return (
            <>
                <Modal
                    visible={visible}
                    onCancel={() => {
                        this.showEditPostModal(false);
                        this.onResetMetas();
                    }}
                    onOk={() => this.handleSubmit()}
                    okButtonProps={{
                        loading: isLoading,
                        size: 'large',
                    }}
                    cancelButtonProps={{ size: 'large' }}
                    okText="Update"
                    closable={false}
                    destroyOnClose
                >
                    <Form>
                        <Form.Item style={{ marginBottom: 0 }}>
                            {getFieldDecorator('content', {
                                initialValue: content,
                                rules: [
                                    {
                                        required: true,
                                        message: 'Please add your content',
                                    },
                                ],
                            })(
                                <Input.TextArea
                                    placeholder="Add your content"
                                    autoSize={{ minRows: 4 }}
                                    onChange={this.onChange}
                                    onPaste={this.onPaste}
                                />
                            )}
                        </Form.Item>
                        {metas && metas.url && (
                            <div className="mt-4">
                                <ConsumerPostMeta
                                    {...metas}
                                    editable
                                    onRemoveMeta={this.onRemoveMeta}
                                    onRemoveImage={this.onRemoveImage}
                                />
                            </div>
                        )}
                        {!isEmpty(images) && (
                            <Form.Item className="mt-2 mb-0">
                                {getFieldDecorator('images', { initialValue: imageKeys })(
                                    <ConsumerPostImageForm
                                        keys={imageKeys}
                                        fileList={fileList}
                                        onUploadImage={this.onUploadImage}
                                        onStoreImageKeys={this.onStoreImageKeys}
                                    />
                                )}
                            </Form.Item>
                        )}
                        {checkIn && (
                            <div className="CheckInBox flex items-center">
                                <div
                                    className="rounded-tl rounded-bl text-base text-white"
                                    style={{
                                        padding: '9px 12px 8px',
                                        backgroundColor: PRIMARY,
                                    }}
                                >
                                    <EnvironmentFilled />
                                </div>
                                <div className="flex-1">
                                    {getFieldDecorator('checkIn', { initialValue: checkIn })(
                                        <Select
                                            className="w-full"
                                            size="large"
                                            allowClear
                                            placeholder="Search suburb"
                                            showSearch
                                            defaultActiveFirstOption={false}
                                            showArrow={false}
                                            filterOption={false}
                                            notFoundContent={isSearching ? <Spin size="small" /> : null}
                                            onSearch={this.handleSearch}
                                            loading={isSearching}
                                        >
                                            {this.renderOptions(dataSource)}
                                        </Select>
                                    )}
                                </div>
                            </div>
                        )}
                    </Form>
                </Modal>
                <div aria-hidden onClick={() => this.showEditPostModal(true)} className="py-1 px-2">
                    {icon === 'loading' ? <LoadingOutlined /> : null}
                    {icon === 'edit' ? <EditOutlined /> : null}
                    &nbsp;Edit
                </div>
            </>
        );
    }
}

const UPDATE_POST_EVENT = gql`
    mutation updatePostEvent($input: UpdatePostEventInput!) {
        updatePostEvent(input: $input) {
            id
            consumerId
            content
            isEdited
            images {
                id
                width
                height
                url
            }
            metaHost
            metaUrl
            metaDescription
            metaImage
            metaTitle
            checkIn
            contributor {
                ... on Consumer {
                    id
                    firstName
                    lastName
                    initials
                    profilePicture
                }
                ... on ConsumerPage {
                    id
                    name
                    profileImage
                    initials
                }
            }
        }
    }
`;

export default Form.create()(
    compose(
        withApollo,
        graphql(UPDATE_POST_EVENT, {
            props: ({ mutate }) => ({ updatePostEvent: (input) => mutate({ variables: { input } }) }),
        })
    )(UpdatePostEvent)
);
