import React, { Component } from 'react';
import { Button } from 'antd';
import { faFacebookMessenger } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';
import { isMobile } from 'react-device-detect';
import { messenger } from 'vanilla-sharing';

class InviteAFriendButton extends Component {
    handleMessengerShareEventClick = () => {
        if (isMobile) {
            messenger({
                url: 'https://www.realty.com.au/sign-up',
                fbAppId: process.env.REACT_APP_NEXT_PUBLIC_FB_APP_ID,
            });
        } else {
            window.FB.ui({
                method: 'send',
                link: 'https://www.realty.com.au/sign-up',
                display: 'popup',
            });
        }
    };

    render() {
        return (
            <Button
                type="primary"
                size="large"
                className="border-none text-white shadow-sm leading-loose w-full rounded-lg hover:text-gray-700"
                onClick={this.handleMessengerShareEventClick}
            >
                <FA icon={faFacebookMessenger} fixedWidth className="mr-1" />
                Invite a Friend
            </Button>
        );
    }
}

export default InviteAFriendButton;
