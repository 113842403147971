import { gql } from '@apollo/client';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { graphql } from '@apollo/client/react/hoc';
import { HeartFilled, HeartOutlined, LoadingOutlined } from '@ant-design/icons';
import { Button, message } from 'antd';
import compose from 'lodash.flowright';

class LikeButton extends Component {
    static propTypes = {
        eventTotalLikes: PropTypes.object,
        id: PropTypes.string,
        isEventLiked: PropTypes.object,
        likeEvent: PropTypes.func,
        unlikeEvent: PropTypes.func,
    };

    static defaultProps = {
        eventTotalLikes: {},
        id: '',
        isEventLiked: {},
        likeEvent: () => {},
        unlikeEvent: () => {},
    };

    state = { isLoadingLikeButton: false };

    getErrorMessage = () => {
        let errorMessage = null;

        switch (this.state.formErrorCode) {
            case 'SERVER_ERROR':
                errorMessage = 'There was a problem liking this event.';
                break;
            default:
                errorMessage = 'Unable to like event.';
                break;
        }
        return errorMessage;
    };

    handleLikeEventClick = () => {
        const { id, likeEvent, isEventLiked, unlikeEvent } = this.props;
        const { event } = isEventLiked;
        const { isEventLikedByConsumer } = event || [];

        message.config({ top: '90%' });

        this.setState({ isLoadingLikeButton: true });
        if (isEventLikedByConsumer) {
            return unlikeEvent(id)
                .then(() => {
                    message.success('Post unliked');
                })
                .catch((error) => {
                    const errors = error.graphqlErrors || [];
                    const formErrorCode = errors.length > 0 ? errors[0].extensions.code : null;
                    this.setState(
                        {
                            formErrorCode,
                            isLoadingLikeButton: false,
                        },
                        () => {
                            message.error(this.getErrorMessage());
                        }
                    );
                })
                .finally(() => {
                    this.setState({ isLoadingLikeButton: false });
                });
        }
        return likeEvent(id)
            .then(() => {
                message.success('Post liked');
            })
            .catch((error) => {
                const errors = error.graphqlErrors || [];
                const formErrorCode = errors.length > 0 ? errors[0].extensions.code : null;
                this.setState(
                    {
                        formErrorCode,
                        isLoadingLikeButton: false,
                    },
                    () => {
                        message.error(this.getErrorMessage());
                    }
                );
            })
            .finally(() => {
                this.setState({ isLoadingLikeButton: false });
            });
    };

    render() {
        const { isLoadingLikeButton } = this.state;
        const { eventTotalLikes, isEventLiked } = this.props;
        const { loading: eventTotalLikesLoading } = eventTotalLikes;
        const { loading: isEventLikedLoading } = isEventLiked;

        if (eventTotalLikesLoading || isEventLikedLoading) {
            return (
                <div className="relative h-12 px-4">
                    <LoadingOutlined className="center-align" />
                </div>
            );
        }

        const {
            event: { totalLikes },
        } = eventTotalLikes;
        const { event } = isEventLiked;
        const { isEventLikedByConsumer } = event || [];

        const icon = (isLoadingLikeButton && <LoadingOutlined />) || (isEventLikedByConsumer && <HeartFilled />) || (
            <HeartOutlined />
        );

        return (
            <button
                type="button"
                className={`h-12 rounded-full rounded-bl-lg border-none bg-transparent px-4 leading-loose ${
                    isEventLikedByConsumer && 'text-blue-500'
                }`}
                onClick={this.handleLikeEventClick}
            >
                {icon}
                &nbsp;
                {totalLikes}
            </button>
        );
    }
}

const IS_EVENT_LIKED = gql`
    query isEventLiked($id: ID!) {
        event(id: $id) {
            id
            isEventLikedByConsumer
        }
    }
`;

const LIKE_EVENT = gql`
    mutation likeEvent($id: ID!) {
        likeEvent(id: $id) {
            id
            totalLikes
            isEventLikedByConsumer
        }
    }
`;
const UNLIKE_EVENT = gql`
    mutation unlikeEvent($id: ID!) {
        unlikeEvent(id: $id) {
            id
            totalLikes
            isEventLikedByConsumer
        }
    }
`;

const EVENT_TOTAL_LIKES = gql`
    query eventTotalLikes($id: ID!) {
        event(id: $id) {
            id
            totalLikes
        }
    }
`;
export default compose(
    graphql(IS_EVENT_LIKED, {
        name: 'isEventLiked',
        options: ({ id }) => ({ variables: { id } }),
    }),
    graphql(LIKE_EVENT, {
        name: 'likeEvent',
        props: ({ likeEvent }) => ({ likeEvent: (id) => likeEvent({ variables: { id } }) }),
    }),
    graphql(UNLIKE_EVENT, {
        name: 'unlikeEvent',
        props: ({ unlikeEvent }) => ({ unlikeEvent: (id) => unlikeEvent({ variables: { id } }) }),
    }),
    graphql(EVENT_TOTAL_LIKES, {
        name: 'eventTotalLikes',
        options: ({ id }) => ({ variables: { id } }),
    })
)(LikeButton);
